









































































































.home-wrapper {
    margin: 0 auto;
    height: calc(100vh - 70px);
    background: url('https://qn.tianqifengyun.cn/pcweb/bg_img.png');
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
}

.download-content {
    position: absolute;
    top: 160px;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.canvas-thunder-left {
    position: absolute;
    display: inline-block;
    width: 426px;
    height: 641px;
    left: 0px;
}

.canvas-thunder-right {
    position: absolute;
    display: inline-block;
    width: 426px;
    height: 641px;
    right: 0px;
}

.ui-left-sdian {
    transform: scale(1.4);
}

.download-content-left {
    width: 594px;
    justify-content: flex-start;

    // height: 533px;
    .download-content-desc {
        width: 564px;
        height: 25px;
        margin-top: 30px;
        justify-content: flex-start;
    }

    .download-slogan {
        width: 564px;
        height: 140px;
        margin-top: 15px;
    }

    .download-panel-group {
        width: 564px;
        margin-top: 50px;
        justify-content: flex-start;
    }
}

.download-desc-bar {
    font-size: 20px;
    color: #fff;
}

.division-bar {
    width: 42px;
    height: 1px;
    background: #FFFFFF;
    opacity: 0.4;
    margin-left: 12px;
    margin-right: 12px;
}

.download-panel {
    padding: 24px;
    width: 208px;
    height: 208px;
    // background: #ffffff;
    border-radius: 4px;

    img {
        width: 160px;
        height: 160px;
    }

    background: linear-gradient(to left, #6B5427, #6B5427) left top no-repeat,
    linear-gradient(to bottom, #6B5427, #6B5427) left top no-repeat,
    linear-gradient(to left, #6B5427, #6B5427) right top no-repeat,
    linear-gradient(to bottom, #6B5427, #6B5427) right top no-repeat,
    linear-gradient(to left, #6B5427, #6B5427) left bottom no-repeat,
    linear-gradient(to bottom, #6B5427, #6B5427) left bottom no-repeat,
    linear-gradient(to left, #6B5427, #6B5427) right bottom no-repeat,
    linear-gradient(to left, #6B5427, #6B5427) right bottom no-repeat;
    /*设置大小*/
    background-size: 2px 30px,
    30px 2px,
    2px 30px,
    30px 2px,
    2px 30px,
    30px 2px,
    2px 30px,
    30px 2px;
}

.download-link-group {
    width: 160px;
    height: 208px;
    justify-content: space-around;
    margin-left: 40px;

    a {
        width: 160px;
        height: 48px;
        border-radius: 25px;
        border: 1px solid rgba(255, 191, 52, 0.3);

        img {
            max-width: 28px;
            max-height: 29px;
        }

        span {
            font-size: 14px;
            color: #FFBF34;
            margin-left: 6px;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid #FFBF34;

        }
    }
}

.download-content-right {
    width: 634px;
    height: 563px;
    text-align: right;

    img {
        width: 634px;
        height: 563px;
        // margin-left: 50px;
    }
}

.home-footer {
    height: 70px;
    background: #232323;
    font-size: 12px;
    color: #9a9a9a;
    text-align: center;

    p {
        margin-bottom: 2px;
        text-align: center;
    }
}