// 配置七牛url
$qiniuUrl: 'https://qn.kuakedata.com';
$base-nav-bar-height: 92px;
$page-top: 16px;
$mg-30: 30px;

// pc commin variable
$pc-width: 1200px;
$pc-common-width: 375Px;

// common color

/** bg color */
$default-bg: #F5F6FA;
$white-bg: #FFFFFF;
$container-bg: #F5F6FA;
$page-bg: #F5F6FA;
$footer-bg: #212B34;

// font color
$white: #fff;
$gray-99: #999999;
$gray-88: #888888;
$gray-66: #666666;
$gray-33: #333333;
$gray-22: #222222;
$gray-00: #000000;
$gray-dde: #DDE0E7;
$gray-f2f: #F2F3F9;
$gray-e5e: #E5E5E5;
$gray-e6e: #E6E6E6;
$gray-eee: #EEECEC;
$gray-bb: #BBBBBB;
$gray-eb: #EBEBEB;

// divider color
$divider-color: #F2F3F9;

$bar-color: #465394;
$blue-color: #118EEE;
$blue-text-color: #118EEE;
$blue-press-color: #384276;
$blue-disable-color: #9098BF;

$red-txt-color: #ED2D28;
$red-btn-color: #D62240;
$red-press-color: #FF294C;

$gray-btn-color: #212B34;
$gray-press-color: #3C4D5D;

$yellow-color: #F0B100;
$yellow-press-color: #D79E00;
$yellow-disable-color: #F5CD5F;
$yellow-selected: #E89A12;

$white-text-color: #fff;
$white-text-disable-color: #9098BF;
$white-color: #FFFFFF;
$white-press-color: #E5E5E5;
$white-disable-color: #FFFFFF;
