@mixin globalFont() {
  font-family: 'Microsoft Yahei', 'MicrosoftYaHei', OpenSans, apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft Yahei', sans-serif;
}
@mixin base-font($weight: 400, $size: 12px, $txt-color: $gray-33) {
  font-weight: $weight;
  font-size: $size;
  line-height: $size;
  font-family: 'Microsoft Yahei', 'MicrosoftYaHei', OpenSans-Semibold, OpenSans, apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC',
    'Hiragino Sans GB', sans-serif;
  color: $txt-color;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// font family OpenSans
@mixin base-font-os($weight: 400, $size: 14px, $txt-color: $gray-33) {
  @include base-font($weight, $size, $txt-color);
  @include globalFont();
}

@mixin base-flex($flex-dir: row) {
  display: flex;
  flex-direction: $flex-dir;
  justify-content: center;
  align-items: center;
}

@mixin base-divider-link($size: 16px) {
  height: $size;
  width: 100vw;
  background-color: $divider-color;
}

@mixin base-divider-link-pc($size: 8Px) {
  height: $size;
  width: 100vw;
  background-color: $divider-color;
}

@mixin disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin base-btn(
  $txt-color: $white-color,
  $txt-press-color: $white-press-color,
  $bg-color: $red-btn-color,
  $bg-press-color: $red-press-color,
  $txt-disable-color: $white-disable-color,
  $bg-disable-color: $red-btn-color
) {
  border-radius: 4px;
  @include base-font(400, 16px, $txt-color);
  @include disable-select;
  background-color: $bg-color;
  ::v-deep >span {
    @include base-font(400, 16px, $txt-color);
  }
  &:active {
    color: $txt-press-color;
    background-color: $bg-press-color;
    ::v-deep >span {
      color: $txt-press-color;
    }
  }
  &:hover {
    color: $txt-press-color;
    background-color: $bg-press-color;
    ::v-deep >span {
      color: $txt-press-color;
    }
  }
  &:disabled {
    ::v-deep >span {
      color: $txt-disable-color !important;
    }
    color: $txt-disable-color !important;
    background-color: $bg-disable-color !important;
    cursor: default;
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
}

@mixin base-btn-pc(
  $txt-color: $white-color,
  $txt-press-color: $white-press-color,
  $bg-color: $blue-color,
  $bg-press-color: $blue-press-color,
  $txt-disable-color: $white-disable-color,
  $bg-disable-color: $blue-disable-color
) {
  border-radius: 8Px;
  @include base-font(400, 15Px, $txt-color);
  @include disable-select;
  background-color: $bg-color;
  ::v-deep >span {
    @include base-font(400, 15Px, $txt-color);
  }
  &:active {
    color: $txt-press-color;
    background-color: $bg-press-color;
    ::v-deep >span {
      color: $txt-press-color;
    }
  }
  &:hover {
    color: $txt-press-color;
    background-color: $bg-press-color;
    ::v-deep >span {
      color: $txt-press-color;
    }
  }
  &:disabled {
    ::v-deep >span {
      color: $txt-disable-color !important;
    }
    color: $txt-disable-color !important;
    background-color: $bg-disable-color !important;
    cursor: default;
    pointer-events: none;
  }
}

.def-btn {
  @include base-btn($white-color, $white-color, $red-btn-color, $red-press-color);
  border: none;
}

.form-btn-blue {
  width: 100%;
  height: 40px;
  border: none;
  @include base-btn();
}

.form-yellow-blue {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: none;
  @include base-btn($white-text-color, $white-press-color, $yellow-color, $yellow-press-color, $white-disable-color, $yellow-disable-color);
}

.form-btn-white {
  @include base-btn($blue-text-color, $blue-press-color, $white-color, $white-press-color, $white-color, $blue-disable-color);
  width: 100%;
  height: 40px;
  border: 2px solid #A7B0DD;
}

.w-full {
  width: 100vw;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-10 {
  z-index: 10;
}
.z-99 {
  z-index: 99;
}

// 水平垂直居中
@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

// 仅垂直居中
@mixin vCenter {
  display: flex;
  align-items: center;
}

@mixin baseEllipsis($width) {
  max-width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin oneEllipsis($width) {
  white-space: nowrap;
  @include baseEllipsis($width);
}
// 不限制单行，主要配合base-font、base-font-os 使用
@mixin noEllipsis() {
  overflow: visible;
  white-space: normal;
  text-overflow: clip;
}
// 最大行数 省略号结尾
@mixin maxLine($lines, $word-break: break-word) {
  white-space: pre-wrap;
  // word-break: break-word; plans 需要 break-word 不然很丑。
  word-break: $word-break;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}

@mixin moreEllipsis($width, $lineNum: 2) {
  @include baseEllipsis($width);
  display: -webkit-inline-box;
  -webkit-line-clamp: $lineNum;
  -webkit-box-orient: vertical;
}

@mixin border-top-1px($color: $gray-eee) {
  border-top: $color solid 1px;
}

@mixin border-top-1p-x-pc($color: $gray-eee) {
  border-top: $color solid 1Px;
}

@mixin border-bottom-1px($color: $gray-eee) {
  border-bottom: $color solid 1px;
}

@mixin border-bottom-1p-x-pc($color: $gray-eee) {
  border-bottom: $color solid 1Px;
}

@mixin flex($direction: 'row') {
  display: flex;
  display: -webkit-flex;
  flex-direction: #{$direction};
}

// 文本前带有一个小蓝块
@mixin section-text($color: $gray-22, $bg: #7584d0) {
  @include vCenter;
  @include base-font-os(600, 26px, $color);
  &::before {
    display: inline-block;
    width: 6px;
    height: 22px;
    margin-right: 14px;
    background: $bg;
    content: '';
  }
}

@mixin section-text-pc($color: $gray-22, $bg: #7584d0) {
  @include vCenter;
  @include base-font-os(600, 13Px, $color);
  &::before {
    display: inline-block;
    width: 3Px;
    height: 11Px;
    margin-right: 7Px;
    background: $bg;
    content: '';
  }
}

@mixin scale-font($color: #666, $size: 24px, $scale: 0.93) {
  font-size: $size;
  color: $color;
  -webkit-transform:scale($scale);
  -ms-transform: scale($scale);
  transform: scale($scale);
}

@mixin data-none-style($logoSize: 400px, $fontSize: 30px) {
  .data-none {
    @include verticalCenter;
    flex-direction: column;
    margin: 150px 0;
    * {
      display: flex;
    }
    .logo {
      img {
        width: $logoSize;
        height: $logoSize;
      }
    }
    .tips {
      margin-top: 60px;
      font-size-adjust: $fontSize;
      color: #666;
      font-size: 30px;
    }
  }
}

.border-1px-class {
  box-sizing: border-box;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 200%;
  height: 200%;
  transform-origin: left top;
  transform: scale(0.5);
}

@mixin border-1px-round ($color, $radius: 0) {
  position: relative;
  &:after {
    border: 1px solid $color;
    border-radius: $radius;
    @extend .border-1px-class;
  }
}

@mixin border-1px ($color, $direction) {
  position: relative;
  &:after {
    border-#{$direction}: 1px solid $color;
    @extend .border-1px-class;
    pointer-events: none;
  }
}

@mixin pc-media($width: 960px) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

::v-deep .el-dialog__header{
	border-bottom: 1px solid #eeeeee;
  color: #3481EF;
  .el-dialog__title{
    color: #333333;
    font-size: 13px;
  }
}
::v-deep .dialog-footer{
  
  .el-button{
    width: 96px;
    height: 32px;
    border-radius: 4px;
    padding: 0;
    line-height: 32px;
    // margin: 0;
    // font-size: 12px !important;
    span{
      display: block;
      width: 96px;
      height: 32px;
      font-size: 12px !important;
      // line-height: 32px;
    }
  }
  .el-button--primary{
    background-color: #D62240;
    border-color: #D62240;
    span{
      color:#ffffff;
    }
  }
  .el-button--default{
    cursor: pointer;
  }
  .el-button--default:focus, .el-button--default:hover{
    color:#666;
    border-color: #DDDDDD;
    background-color:#fff;
  }
}