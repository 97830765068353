html, body, #__nuxt, #__layout
{
  @include globalFont();
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  @include globalFont();
}

div, span, pre {
  font-size: 24px;
  @include globalFont();
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}

p {
  margin: 0;
  padding: 0;
  @include globalFont();
}

h1, h2, h3, h4, h5, h6, p, a, span, pre, div { // 禁止进行选择
  -webkit-touch-callout:none;  /* 系统默认菜单被禁用 */
  -webkit-user-select:none; /* webkit浏览器 */
  -khtml-user-select:none; /* 早期浏览器 */
  -moz-user-select:none; /* 火狐 */
  -ms-user-select:none; /* IE10 */
  user-select:none;
}

h1, h2, h3, h4, h5, h6, p, a, span, pre, div, img, input, button {
  box-sizing: border-box;
}

img[alt] {
  font-size: 12Px;
  color: $gray-66;
}

.clearfix {
  zoom: 1;
}

.clearfix::after {
  clear: both;
  content: '';
  display: block;
}

.disable-click {
  pointer-events: none;
  cursor: not-allowed;
}

.disable-select {
  @include disable-select;
}

.W-color,
.O-color {
  color: #E5041A!important;
}
.L-color,
.U-color {
  color: #5AA900!important;
}
.D-color,
.V-color {
  color: #3481EF!important;
}

.fake-div {
  display: block;
  text-decoration: none;
}

.hdn-box {
  pointer-events: none;
  position: fixed;
  top: 1800px;
  left: 0;
  z-index: -1;
  opacity: 0;
}

/* navbar 通用组件 */
.k-text {
  font-size: 14Px;
  font-family: MicrosoftYaHei;
  font-weight: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.k-btn {
  border-radius: 4px;
  font-size: 14Px;
  font-family: MicrosoftYaHei;
  font-weight: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #FFF;
  cursor: pointer;
}
/* 基础css 通用组件 */
.fn-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fn-flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
